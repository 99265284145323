import React, { useEffect, useMemo } from "react";
import { Box, Button, CircularProgress, Divider, Grid, LinearProgress, Typography } from "@mui/material";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import ExistingImagesSection from "../ExistingImagesSection";
import EventPageStore from "../store";
import { observer } from "mobx-react";
import DeleteEventModal from "../DeleteEventModal";
import ScanEventImagesModal from "./ScanEventImagesModal";
import StatBlock from "../../MyEventsPage/StatBlock";
import ImagesUploader from "../../../components/ImagesUploader";
import CopyLinkCard from "../../../components/CopyLinkCard";
import QrCode from "../../../components/QrCode";
import { join } from "path";
import PracticipentsFilePicker from "../NonActiveEventPage/PracticipentsFilePicker";
import { toast } from "react-toastify";
import StartEventModal from "../NonActiveEventPage/StartEventModal";

interface IActiveEventPageProps {
    activePracticipantsCount: number;
    allPracticipantsCount: number;
    imagesLimit: number;
    name: string;
    joinEventLinkUrl?: string;
}

const ActiveEventPage: React.FC<IActiveEventPageProps> = observer(({ activePracticipantsCount, allPracticipantsCount, name, imagesLimit, joinEventLinkUrl }) => {
    console.log("Join event link:", joinEventLinkUrl)
    const uploadedImages = EventPageStore.imagesCount;
    const uploadProgress = Math.round((uploadedImages / imagesLimit) * 100);

    const handleDeleteEventModalOpen = () => {
        EventPageStore.setDeleteEventModalOpen(true);
    };

    const handleScanEventModalOpen = () => {
        EventPageStore.setScanEventModalOpen(true);
    }

    const handleOpenStartEventModal = () => {
        if (EventPageStore.practicipents.length === 0) {
            toast.error('יש להזין מסמך אקסל', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                theme: "light",
            });
            return;
        }

        EventPageStore.setStartEventModalOpen(true);
    };

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100vw' // ensures the Box takes full width of the viewport
        }}>
            <Box sx={{ maxWidth: '800px', width: '100%', textAlign: 'start' }}>
                <Typography variant="h4" fontWeight="bold" mb={1}>
                    האירוע של {name}
                </Typography>
                <Typography variant="body1" color="grey" gutterBottom>
                    האירוע מתקיים ברגעים אלו...
                </Typography>
                <LinearProgress variant="determinate" value={uploadProgress} sx={{ width: '100%', height: '10px', marginBottom: '5px', borderRadius: "16px" }} />
                <Typography variant="body2" color="grey" textAlign="start" gutterBottom>
                    {uploadedImages}/{imagesLimit} תמונות הועלו
                </Typography>
                <Grid container direction="row" justifyContent="space-around" alignItems="center">
                    <Grid item xs={4}>
                        <StatBlock count={uploadedImages} label="סך הכל תמונות שהועלו" />
                    </Grid>
                    <Grid item xs={4} >
                        <StatBlock count={activePracticipantsCount} label="משתתפים פעילים" />
                    </Grid>
                    <Grid item xs={4} >
                        <StatBlock count={allPracticipantsCount} label="סך הכל משתתפים" />
                    </Grid>
                </Grid>
                <Box mt={3}>
                    <PracticipentsFilePicker />
                </Box>
                <Box mt={3}>
                    <ImagesUploader />
                </Box>
                <Box mt={5}>
                    <ExistingImagesSection images={EventPageStore.fewImagesFromEvent || []} />
                </Box>
                <Box>
                    {
                        joinEventLinkUrl &&
                        <Box mt={5} sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                            <Box sx={{ flex: 1 }}>
                                <QrCode url={joinEventLinkUrl} />
                            </Box>
                            <Typography sx={{ marginX: 2 }}>או</Typography>
                            <Box sx={{ flex: 1, marginLeft: 2 }}>
                                <CopyLinkCard link={joinEventLinkUrl} />
                            </Box>
                        </Box>
                    }

                    <Box mt={5} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <Button variant="contained" sx={{ borderRadius: '16px', width: '100%' }} onClick={handleScanEventModalOpen}>
                            סרוק אירוע
                        </Button>
                        <Button variant="contained" sx={{ borderRadius: '16px', width: '100%' }} onClick={handleDeleteEventModalOpen}>
                            סיים אירוע
                        </Button>
                        <Button variant="contained" color="primary" sx={{ marginTop: "10px", width: "400px" }} onClick={handleOpenStartEventModal}>
                            🚀     שגר אירוע
                        </Button>
                    </Box>
                </Box>
            </Box>
            {EventPageStore.startEventModalOpen && < StartEventModal />}
            {EventPageStore.deleteEventModalOpen && <DeleteEventModal />}
            {EventPageStore.scanEventModalOpen && <ScanEventImagesModal />}
        </Box>
    );
});

export default ActiveEventPage;
