import { resetPassword } from "../../api";

interface ForgotMyPasswordPageProps {

}

const ForgotMyPasswordPage: React.FC<ForgotMyPasswordPageProps> = () => {
    const sendPasswordResetEmail = async () => {
        await resetPassword("coldcube21@gmail.com");
    }

    return (
        <div>
            <button onClick={sendPasswordResetEmail}> send reset password </button>
        </div>
    );
}

export default ForgotMyPasswordPage;