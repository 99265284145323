import { Routes, Route } from "react-router-dom";
import RequireAuth from "./RequireAuth";
import Events from "./pages/MyEventsPage";
import EventPage from "./pages/EventPage";
import { LoginForm } from "./pages/LoginPage";
import { SignupForm } from "./pages/SignUpPage";
import ForgotMyPasswordPage from "./pages/ResetPasswordPage";
import SideNavbar from "./components/NavBar";
import MyPackages from "./pages/MyPackages";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <div style={{ display: 'flex', minHeight: '100vh', direction: 'rtl' }}>
      <SideNavbar />
      <Routes>
        <Route path="/" element={<LoginForm />} />
        <Route path="/login" element={<LoginForm />} />
        <Route path="/signup" element={<SignupForm />} />
        <Route path="/reset-password" element={<ForgotMyPasswordPage />} />
        <Route path="/events" element={<RequireAuth><Events /></RequireAuth>} />
        <Route path="/packages" element={<RequireAuth><MyPackages /></RequireAuth>} />
        <Route path="/event/:eventId" element={<RequireAuth><EventPage /></RequireAuth>} />
      </Routes>
      <ToastContainer />
    </div>
  );
}

export default App;
