import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { LinearProgress, Button, Box, Typography } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { getEventImagesCount, getFewImagesFromEvent, uploadImagesToEvent } from '../api';
import EventPageStore from '../pages/EventPage/store';

const batchSize = 100;
const ImagesUploader: React.FC<{}> = () => {
    const [files, setFiles] = useState<File[]>([]);
    const [isUploading, setIsUploading] = useState(false);
    const { eventId } = useParams();

    const onDrop = useCallback((acceptedFiles: File[]) => {
        setFiles(currentFiles => [...currentFiles, ...acceptedFiles]);
    }, []);


    const uploadFiles = async () => {
        setIsUploading(true);

        try {
            for (let i = 0; i < files.length; i += batchSize) {
                const batch = files.slice(i, i + batchSize);
                const result = await uploadImagesToEvent(eventId!, batch) as any;
                if (result.status === 400 && result.data.errorCode === 'IMAGES_LIMIT_REACHED') {
                    toast.error('הגעת למגבלת התמונות לאירוע זה!', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        theme: "light",
                    });
                    return; // Exit the function if the image limit is reached
                }
            }

            if (EventPageStore.fewImagesFromEvent?.length === 0) {
                const images = await getFewImagesFromEvent(eventId!);
                EventPageStore.setFewImagesFromEvent(images);
            }
            const imagesCount = await getEventImagesCount(eventId!);
            EventPageStore.setImagesCount(imagesCount);

            toast.success('התמונות הועלו בהצלחה!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                theme: "light",
            });
        } catch (error) {
            console.error('An error occurred during the upload process:', error);
            toast.error('אירעה שגיאה בעת העלאת התמונות.', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                theme: "light",
            });
        } finally {
            setFiles([]);
            setIsUploading(false); // Ensure isUploading is set to false when all uploads are done or an error occurs
        }
    };



    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop, accept: {
            "image/png": [".png"],
            "image/jpeg": [".jpeg"],
            "image/jpg": [".jpg"],
        }
    });

    return (
        <>
            <Typography textAlign="left" variant="h5" fontWeight="600">
                העלה תמונות
            </Typography>
            <Box
                {...getRootProps()}
                component="label"
                htmlFor="excel-upload"
                sx={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: "100%",
                    height: 250,
                    border: '2px dashed #d9d9d9',
                    borderRadius: '16px',
                    cursor: 'pointer',
                    transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
                    '&:hover': {
                        transform: 'scale(1.05)',
                        boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
                    },
                    backgroundColor: isDragActive ? '#aaa' : '#fff',
                }}
            >
                <input {...getInputProps()} />
                <Typography variant="h6" fontWeight="bold">גרור ושחרר תמונות לכאן</Typography>
                <Button variant="contained" sx={{ borderRadius: '16px', mt: 2, color: "theme.primary" }}>
                    <strong> בחר תמונות מהמחשב </strong>
                </Button>
                <Typography variant='subtitle1' fontWeight="600" mt={1}> נבחרו {files.length} תמונות </Typography>
            </Box>
            <LinearProgress variant="determinate" value={isUploading ? undefined : 0} />
            <Button variant="contained" sx={{ marginTop: "10px", width: "100%", borderRadius: "12px" }} onClick={uploadFiles} disabled={isUploading}> העלה תמונות </Button>
        </>
    );
}

export default ImagesUploader;
