import axios, { AxiosError } from "axios";
import { IEvent } from "./models/IEvent";
import { IUser } from "./models/IUser";

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.request.use(
    config => {
        const token = JSON.parse(localStorage.getItem('user') as string)?.token;
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    error => Promise.reject(error)
);

api.interceptors.response.use(
    response => response,
    (error: AxiosError) => {
        if (error.response?.status === 401) {
            logoutUser();
        }
        return Promise.reject(error);
    }
);

// auth.js
export const logoutUser = () => {
    localStorage.removeItem('user');
    window.location.href = '/login';
};


const getUser = (): IUser => {
    return JSON.parse(localStorage.getItem('user') as string);
};

export const getEventById = async (eventId: string) => {
    try {
        const response = await api.get(`/event/getEventById/${eventId}`);
        return response.data.event;
    } catch (error) {
        return handleApiError(error);
    }
};

export const getEventNameById = async (eventId: string) => {
    try {
        const response = await api.get(`/event/getEventNameById/${eventId}`);
        return response.data.eventName;
    } catch (error) {
        return handleApiError(error);
    }
};

export const scanEventImages = async (eventId: string) => {
    try {
        await api.post('/event/scanEvent', { eventId });
    } catch (error) {
        return handleApiError(error);
    }
};

export const deleteEvent = async (eventId: string) => {
    try {
        await api.post('/event/deleteEvent', { eventId });
    } catch (error) {
        return handleApiError(error);
    }
};

export const scanEvent = async (eventId: string) => {
    try {
        await api.post('/event/scanEvent', { eventId });
    } catch (error) {
        return handleApiError(error);
    }
};

export const startEvent = async (event: Partial<IEvent>) => {
    try {
        const response = await api.post('/event/startEvent', { eventId: event.id, practicipents: event.practicipants });
        return response;
    } catch (error) {
        return handleApiError(error);
    }
};

export const fetchEvents = async () => {
    try {
        const userId = getUser().userId;
        const response = await api.get(`/event/getEvents/${userId}`);
        return response.data.events;
    } catch (error) {
        return handleApiError(error);
    }
};

export const getEventsPage = async () => {
    try {
        const userId = getUser().userId;
        const response = await api.get(`/event/getEventsPage/${userId}`);
        return response.data.eventsPage;
    } catch (error) {
        return handleApiError(error);
    }
};

export const createEvent = async (eventName: string, eventDate: string, packageName: string) => {
    try {
        const userId = getUser().userId;
        const response = await api.post('/event/createEvent', { name: eventName, userId: userId, packageName, eventDate });
        return response.data.eventId;
    } catch (error) {
        return handleApiError(error);
    }
};

export const getPresignedUrl = async (eventId: string, file: File) => {
    try {
        const response = await api.post('/s3/createPresignedUrl', {
            fileName: `${eventId}/${file.name}`,
            contentType: file.type,
        });
        return response.data.url;
    } catch (error) {
        return handleApiError(error);
    }
};

export const getFewImagesFromEvent = async (eventId: string) => {
    try {
        const response = await api.get(`/s3/getFewImagesFromEvent/${eventId}`);
        return response.data.fewImagesFromTheEvent;
    } catch (error) {
        return handleApiError(error);
    }
};

export const getEventPage = async (eventId: string) => {
    try {
        const response = await api.get(`/event/getEventPage/${eventId}`);
        return response.data;
    } catch (error) {
        return handleApiError(error);
    }
};

export const uploadImagesToEvent = async (eventId: string, files: File[]) => {
    const formData = new FormData();
    files.forEach(file => formData.append('files', file));

    try {
        const response = await api.post(`/event/uploadImagesToEvent/${eventId}`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
        });
        return response;
    } catch (error) {
        return handleApiError(error);
    }
};

export const getEventImagesCount = async (eventId: string) => {
    try {
        const response = await api.get(`/event/getEventImagesCount/${eventId}`);
        return response.data.imagesCount;
    } catch (error) {
        console.error('Failed to get event images count:', error);
    }
};

export const login = async (email: string, password: string) => {
    try {
        const response = await api.post('/login', { email, password });
        return response;
    } catch (error) {
        console.error('Failed to login:', error);
    }
};

export const signup = async (email: string, password: string, phone: string, firstName: string, fammilyName: string) => {
    try {
        const response = await api.post('/signup', { email, password, firstName, fammilyName, phone });
        return response;
    } catch (error: any) {
        return error.response;
    }
};

export const isTokenValid = async (token: string) => {
    try {
        const response = await api.get(`/validateToken/${token}`);
        return response.status === 200;
    } catch (error) {
        console.error('Failed to check token:', error);
    }
};

export const resetPassword = async (email: string) => {
    const response = await api.post('/resetPassword', { email });
    return response;
};

export const getUserPackages = async () => {
    try {
        const userId = getUser().userId;
        const response = await api.get(`/getUserPackages/${userId}`);
        return response.data.packages;
    } catch (error) {
        console.error('Failed to get user packages:', error);
    }
};

// Helper function to handle API errors
const handleApiError = (error: unknown) => {
    if (axios.isAxiosError(error)) {
        return error.response;
    } else {
        throw new Error('An unexpected error occurred');
    }
};