import { Box, Button, CircularProgress, Divider, Fade, Modal, Typography } from "@mui/material";
import EventPageStore from "./store";
import { useQuery } from "react-query";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import { deleteEvent } from "../../api";

const DeleteEventModal: React.FC<{}> = observer(() => {
    const navigate = useNavigate();

    const handleClose = () => {
        EventPageStore.setDeleteEventModalOpen(false);
    }

    const handleDeleteEvent = async () => {
        await deleteEvent(EventPageStore.eventId);

        EventPageStore.setIsEventActive(false);
        handleClose();
        navigate('/events');
    };

    const { isError, isLoading: isDeleteEventLoading, refetch } = useQuery(["updatePraciticpents"], handleDeleteEvent, {
        enabled: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false
    })

    useEffect(() => {
        if (isError)
            toast.error('משהו נכשל במחיקת האירוע...', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                theme: "light",
            });
    }, [isError]);

    return (
        <Modal
            open={EventPageStore.deleteEventModalOpen}
            onClose={handleClose}
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Box
                sx={{
                    maxWidth: '90vw', // maximum width of 90% of the viewport width
                    maxHeight: '90vh', // maximum height of 90% of the viewport height
                    backgroundColor: 'white',
                    borderRadius: '10px',
                    padding: '20px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    overflow: 'auto' // add a scrollbar if the contents are too large
                }}
            >
                <Typography variant='h5' > ? האם ברצונך למחוק ולסיים את  האירוע</Typography>
                <Divider sx={{ width: "100%" }} />
                <Typography variant='h5' color="red"> ⚠️⚠️⚠️ זהירות </Typography>
                <Typography variant='h6' color='gray'> בלחיצה על "כן" האירוע ימחק ויסתיים </Typography>
                <Typography variant='h6' color='gray'> כמו כן ימחקו כל המשאבים הרלוונטים לאירוע </Typography>
                <Box sx={{ display: 'flex', gap: '10px', marginTop: "20px" }}>
                    <Button variant='contained' color='primary' onClick={() => refetch()}>
                        {isDeleteEventLoading ? (
                            <Fade
                                in={isDeleteEventLoading}
                                style={{
                                    transitionDelay: isDeleteEventLoading ? '800ms' : '0ms',
                                }}
                                unmountOnExit
                            >
                                <CircularProgress color="inherit" size={24} />
                            </Fade>
                        ) : "כן"}
                    </Button>
                    <Button variant='contained' color="error" onClick={handleClose}>לא</Button>
                </Box>
            </Box>
        </Modal>
    );
});

export default DeleteEventModal;