import React, { useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { useQuery } from "react-query";
import CreateEventModal from "./CreateEventModal";
import { getEventsPage } from "../../api";
import StatBlock from "./StatBlock";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import EventsTable from "./EventsTable";
import { IEventsPage } from "../../models/IEventPage";
import dancingAnimation from "../../assets/lottie/dancing.json";
import Lottie from "lottie-react";

const Events: React.FC = () => {
	const {
		data: eventsPageData,
		error,
		isLoading,
		refetch
	} = useQuery<IEventsPage>("events", getEventsPage);

	const [isCreateEventModalOpen, setIsCreateEventModalOpen] = useState(false);

	const handleCloseModal = () => {
		setIsCreateEventModalOpen(false);
	};

	const handleOpenModal = () => {
		setIsCreateEventModalOpen(true);
	};

	return (
		isLoading ? (<Box sx={{ width: "150px", height: "150px" }}>
			<Lottie animationData={dancingAnimation} loop={true} />
		</Box>) :
			<>
				<Box sx={{ flexGrow: 1, m: 2 }}>
					<Grid container gap={3} justifyContent="center">
						<Grid item xs={12} ml={1}>
							<Typography variant="h4" fontWeight="bold" textAlign="start" mb={1}>
								האירועים שלי
							</Typography>
							<Typography variant="body1" color="grey" gutterBottom textAlign="start">
								כאן תוכל לראות את כל האירועים שיצרת ולנהל אותם
							</Typography>
						</Grid>
						<Grid container item spacing={2} gap={2} marginLeft="2px">
							<StatBlock count={eventsPageData?.events?.length || 0} label="סך הכל אירועים" />
							<StatBlock count={eventsPageData?.activeEvents?.length || 0} label="אירועים פעילים" />
							<StatBlock count={eventsPageData?.activeParticipantsCount || 0} label="סך הכל משתתפים פעילים" />
							<StatBlock count={eventsPageData?.imagesCount || 0} label="סך הכל תמונות" />
						</Grid>
						<Box
							sx={{
								mt: 2, // Margin at the top of the box
								border: '1px solid',
								borderColor: '#d3d3d3', // Use your theme's primary color
								borderRadius: '8px', // Rounded corners
								padding: 2, // Padding inside the border
								marginBottom: 2, // Margin at the bottom of the box
								width: "100%"
							}}
						>
							<Grid container item spacing={2} alignItems="center" justifyContent="space-between">
								<Grid item>
									<Typography variant="h6" fontWeight="bold">
										צור אירוע חדש
									</Typography>
									<Typography variant="body2" color="grey">
										לחץ על הכפתור כדי להתחיל
									</Typography>
								</Grid>
								<Grid item>
									<Button variant="contained" onClick={handleOpenModal} sx={{ borderRadius: '8px' }}>
										צור אירוע
									</Button>
								</Grid>
							</Grid>
						</Box>
						<EventsTable events={eventsPageData?.events || []} />
					</Grid>
				</Box>

				{/* Create Event Modal */}
				<CreateEventModal
					open={isCreateEventModalOpen}
					onClose={handleCloseModal}
					refetch={refetch}
					isLoading={isLoading}
				/>
			</>
	);
};

export default Events;
