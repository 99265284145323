import React, { useEffect, useMemo } from "react";
import { Box, Button, CircularProgress, MenuItem, Select, TextField, Typography } from "@mui/material";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { SubmitHandler, useForm } from "react-hook-form";
import { ajvResolver } from '@hookform/resolvers/ajv';
import ExistingImagesSection from "../ExistingImagesSection";
import PracticipentsFilePicker from "./PracticipentsFilePicker";
import EventPageStore from "../store";
import { toast } from "react-toastify";
import StartEventModal from "./StartEventModal";
import { observer } from "mobx-react";
import { useQuery } from "react-query";
import DeleteEventModal from "../DeleteEventModal";
import ImagesUploader from "../../../components/ImagesUploader";

const schema: any = {
    type: 'object',
    properties: {
        eventName: {
            type: 'string',
            minLength: 1,
            errorMessage: { minLength: 'event name field is required' },
        },
        eventType: {
            type: 'string',
            minLength: 1,
            errorMessage: { minLength: 'event type field is required' },
        },
    },
    required: ['eventName', 'eventType'],
    additionalProperties: false,
};

interface IFormInput {
    eventName: string;
    eventType: string;
}

interface INonActiveEventPage {
    name: string;
}

const NonActiveEventPage: React.FC<INonActiveEventPage> = observer(({ name }) => {
    const handleOpenStartEventModal = () => {
        if (EventPageStore.practicipents.length === 0) {
            toast.error('יש להזין מסמך אקסל', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                theme: "light",
            });
            return;
        }

        EventPageStore.setStartEventModalOpen(true);
    };

    const handleDeleteEventModalOpen = () => {
        EventPageStore.setDeleteEventModalOpen(true);
    };

    const { register, handleSubmit, formState: { errors } } = useForm<IFormInput>({
        resolver: ajvResolver(schema)
    });

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            m: 2,
            width: '100vw'
        }}>
            {
                EventPageStore.isLaunchingEvent ?
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <CircularProgress />
                        <Typography variant="h5" color="grey" mt={2}>
                            שוגר אירוע...
                        </Typography>
                    </Box>
                    :

                    <Box sx={{ maxWidth: '600px', width: '100%', textAlign: 'center' }}>
                        <Typography variant="h4" fontWeight="bold" mb={1}>
                            יצירת אירוע חדש
                        </Typography>
                        <Typography variant="body1" color="grey" gutterBottom>
                            כאן תוכל/י ליצור אירוע חדש
                        </Typography>
                        <TextField
                            sx={{ width: "100%", ".MuiOutlinedInput-root": { borderRadius: "16px" } }}
                            label="שם אירוע"
                            variant="outlined"
                            defaultValue={name}
                            error={!!errors.eventName}
                            helperText={errors.eventName ? "אנא מלא פרטים" : ""}
                            {...register("eventName")}
                            fullWidth
                            margin="normal"
                        />
                        <Select
                            dir="rtl"
                            defaultValue="חתונה"
                            error={!!errors.eventType}
                            {...register("eventType")}
                            sx={{ width: "100%", '.MuiOutlinedInput-notchedOutline': { borderRadius: '16px' } }}
                        >
                            <MenuItem value="חתונה" dir="rtl">חתונה</MenuItem>
                            <MenuItem value="חינה" dir="rtl">חינה</MenuItem>
                        </Select>
                        <PracticipentsFilePicker />
                        <ImagesUploader />
                        <ExistingImagesSection images={EventPageStore.fewImagesFromEvent || []} />
                        <>
                            <Button variant="contained" sx={{ marginTop: "10px", width: "400px" }} onClick={handleDeleteEventModalOpen}>
                                מחק אירוע 🗑️
                            </Button>
                            <Button variant="contained" color="primary" sx={{ marginTop: "10px", width: "400px" }} onClick={handleOpenStartEventModal}>
                                🚀     שגר אירוע
                            </Button>
                        </>
                    </Box>
            }
            {EventPageStore.startEventModalOpen && < StartEventModal />}
            {EventPageStore.deleteEventModalOpen && < DeleteEventModal />}
        </Box>
    );
});

export default NonActiveEventPage;
