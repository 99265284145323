import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Button, Paper, TextField, Link as MuiLink, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { login } from '../../api';
import Ajv from "ajv";
import { errorToast } from '../../toastAlerts';
import addFormats from "ajv-formats";
import { AuthContext } from '../../Context/AuthContext';
import logo from '../../assets/images/memora_logo.jpeg';

const ajv = new Ajv({ allErrors: true });
addFormats(ajv);

const schema = {
    type: "object",
    properties: {
        email: { type: "string", format: "email" },
        password: { type: "string", minLength: 8 }
    },
    required: ["email", "password"],
    additionalProperties: false
};

const validate = ajv.compile(schema);

export const LoginForm: React.FC = () => {
    const [isLoading, setIsLoading] = useState(false);
    const { setCurrentUser } = useContext(AuthContext);
    const { register, handleSubmit, setError, formState: { errors } } = useForm({
        mode: "onBlur",
        criteriaMode: "all"
    });
    const navigate = useNavigate();

    const onSubmit = async (data: any) => {
        if (!validate(data)) {
            validate.errors?.forEach(error => {
                setError(error.instancePath.substring(1), { type: "manual", message: error.message });
            });
            return;
        }

        try {
            setIsLoading(true);
            const response = await login(data.email as string, data.password as string);
            if (response?.status === 200 && response.data) {
                const user = response.data;
                localStorage.setItem('user', JSON.stringify(user));
                setCurrentUser(user);
                setIsLoading(false);
                navigate('/events');
                return;
            }

            errorToast("סיסמא או שם משתמש לא נכונים");

        } catch (error: any) {
            errorToast("סיסמא או שם משתמש לא נכונים");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '100vh',
            width: '100vw'
        }}>
            <Paper elevation={3} sx={{ padding: 3, width: "500px", boxSizing: 'border-box' }}>
                <Box display="flex" justifyContent="center" mb={2}>
                    <img src={logo} alt="Logo" style={{ width: "200px", height: "200px" }} />
                </Box>
                <h2>התחברות</h2>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <TextField
                        fullWidth
                        label="אימייל"
                        variant="outlined"
                        {...register("email", { required: "Email is required" })}
                        error={!!errors.email}
                        helperText={(errors.email && typeof errors.email.message === 'string') ? errors.email.message : ""}
                        sx={{ marginBottom: 2 }}
                    />
                    <TextField
                        fullWidth
                        label="סיסמה"
                        type="password"
                        variant="outlined"
                        {...register("password", { required: "Password is required" })}
                        error={!!errors.password}
                        helperText={(errors.password && typeof errors.password.message === 'string') ? errors.password.message : ""}
                        sx={{ marginBottom: 2 }}
                    />
                    <Box mb={2} display="flex" justifyContent="space-between">
                        <MuiLink onClick={() => navigate('/reset-password')} style={{ cursor: 'pointer', color: 'gray', textDecoration: 'none' }}>
                            שכחתי סיסמה
                        </MuiLink>
                        <MuiLink onClick={() => navigate('/signup')} style={{ cursor: 'pointer', color: 'gray', textDecoration: 'none' }}>
                            הירשם
                        </MuiLink>
                    </Box>
                    <Button type="submit" variant="contained" color="primary" fullWidth sx={{ height: "45px" }}> {isLoading ? <CircularProgress /> : "התחבר"}</Button>
                </form>
            </Paper>
        </Box>
    );
};
