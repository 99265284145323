export const packages = [
    {
        title: "⭐️ חבילת בייסיק",
        description: "הפתרון הפשוט והיעיל ⭐️",
        discountPrice: 419,
        originalPrice: 600,
        name: "basic",
        features: [
            "שיתוף תמונות באמצעות לינק ייעודי להפצה עצמית",
            "QR Code מותאם אישית עם מסגרת מעוצבת להדפסה ושילוב באירוע",
            "מערכת זיהוי פנים מתקדמת להעלאת סלפי וקבלת תמונות אישיות בוואטסאפ",
            "עד 1,500 תמונות באירוע"
        ],
        buttonText: "קנה עכשיו"
    },
    {
        title: "🌟 חבילת פרמיום",
        description: "החוויה האולטימטיבית לאירועים 🌟",
        discountPrice: 559,
        originalPrice: 800,
        name: "premium",
        features: [
            "שליחת הודעות וואטסאפ אישיות לכל משתתף",
            "שיתוף תמונות חכם על בסיס זיהוי פנים מתמונות הצלם",
            "QR Code מותאם אישית עם מסגרת מעוצבת להדפסה ושילוב באירוע",
            "עד 3,000 תמונות",
            "עד 500 הודעות אישיות"
        ],
        buttonText: "אני רוצה חבילת פרמיום!"
    },
    {
        title: "🌟 חבילת זהב",
        description: "השירות המושלם לאירועים גדולים 🌟",
        name: "gold",
        discountPrice: 2380,
        originalPrice: 3400,
        features: [
            "שליחת הודעות וואטסאפ אישיות לכל משתתף",
            "שיתוף תמונות חכם על בסיס זיהוי פנים",
            "אפשרות לאורחים לשתף תמונות שצילמו ישירות דרך הבוט",
            "QR Code מותאם אישית עם מסגרת מעוצבת להדפסה ושילוב באירוע",
            "עד 10,000 תמונות",
            "עד 3,000 הודעות אישיות"
        ],
        buttonText: "לפרטים נוספים"
    }
];