
import { useNavigate } from "react-router-dom";
import { createContext, useState, useEffect, ReactNode } from "react";
import { IUser } from "../models/IUser";

interface Props {
  children?: ReactNode
}

export const AuthContext = createContext({
  currentUser: {} as IUser | null,
  setCurrentUser: (_user: IUser) => { },
  signOut: () => { }
});

export const AuthProvider = ({ children }: Props) => {
  const [currentUser, setCurrentUser] = useState<IUser | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user') || "{}") as IUser | null;
    if (user) {
      setCurrentUser(user);
    }
  }, []);

  const signOut = () => {
    localStorage.removeItem('user');
    setCurrentUser(null);
    navigate('/login');
  };

  const value = {
    currentUser,
    setCurrentUser,
    signOut,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};