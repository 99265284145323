import { useState, useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { isTokenValid } from "./api";

function RequireAuth({ children }: { children: JSX.Element }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const token = JSON.parse(localStorage.getItem('user') || "{}").token;
  let location = useLocation();

  useEffect(() => {
    if (!token) {
      setLoading(false);
      return;
    }
    isTokenValid(token).then((response) => {
      if (!response) {
        localStorage.removeItem('user');
        setIsAuthenticated(false);
      } else {
        setIsAuthenticated(true);
      }
      setLoading(false);
    });
  }, [token]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
}

export default RequireAuth;
