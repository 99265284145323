import { makeAutoObservable } from "mobx";
import { IPracticipant } from "../../models/IPracticipant";

type EventType = "חינה" | "חתונה";
export class EventPageStore {
    public practicipents: IPracticipant[];
    public startEventModalOpen: boolean;
    public deleteEventModalOpen: boolean;
    public scanEventModalOpen: boolean;
    public eventId: string;
    public isEventActive: boolean;
    public eventType: EventType;
    public eventName: string;
    public eventDate: string;
    public fewImagesFromEvent: string[];
    public isLaunchingEvent: boolean;
    public imagesCount: number;

    public constructor() {
        this.practicipents = [];
        this.startEventModalOpen = false;
        this.deleteEventModalOpen = false;
        this.scanEventModalOpen = false;
        this.eventId = '';
        this.eventDate = '';
        this.isEventActive = false;
        this.eventType = "חתונה";
        this.eventName = "";
        this.fewImagesFromEvent = [];
        this.isLaunchingEvent = false;
        this.imagesCount = 0;

        makeAutoObservable(this);
    }

    public clear(): void {
        this.practicipents = [];
        this.startEventModalOpen = false;
        this.scanEventModalOpen = false;
        this.deleteEventModalOpen = false;
        this.eventId = '';
        this.isEventActive = false;
    }

    public setEventDate(eventDate: string): void {
        this.eventDate = eventDate;
    }

    public setImagesCount(imagesCount: number): void {
        this.imagesCount = imagesCount;
    }

    public setIsLaunchingEvent(isLaunchingEvent: boolean): void {
        this.isLaunchingEvent = isLaunchingEvent;
    }

    public setFewImagesFromEvent(fewImagesFromEvent: string[]): void {
        this.fewImagesFromEvent = fewImagesFromEvent;
    }

    public setEventName(eventName: string): void {
        this.eventName = eventName;
    }

    public setEventType(eventType: EventType): void {
        this.eventType = eventType;
    }

    public setIsEventActive(isEventActive: boolean): void {
        this.isEventActive = isEventActive;
    }

    public setEventId(eventId: string): void {
        this.eventId = eventId;
    }

    public setPracticipents(practicipents: IPracticipant[]): void {
        this.practicipents = practicipents;
    }

    public setScanEventModalOpen(scanEventModalOpen: boolean): void {
        this.scanEventModalOpen = scanEventModalOpen;
    }

    public setStartEventModalOpen(startEventModalOpen: boolean): void {
        this.startEventModalOpen = startEventModalOpen;
    }

    public setDeleteEventModalOpen(deleteEventModalOpen: boolean): void {
        this.deleteEventModalOpen = deleteEventModalOpen;
    }

}

export default new EventPageStore();