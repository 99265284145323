import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import "react-toastify/dist/ReactToastify.css";
import EventPageStore from './store';
import { observer } from 'mobx-react';
import ActiveEventPage from './ActiveEventPage';
import NonActiveEventPage from './NonActiveEventPage';
import { CircularProgress } from '@mui/material';
import { getEventPage } from '../../api';

interface IEventPage {
    fewImagesFromTheEvent: string[];
    name: string;
    imagesCount: number;
    activePracticipantsCount: number;
    allPracticipantsCount: number;
    isActive: boolean;
    joinEventLink?: string;
}

const EventPage: React.FC<{}> = observer(({ }) => {
    const { eventId } = useParams();

    if (!eventId) throw new Error("Event id is missing");
    const { data: eventPage, error, isLoading, } = useQuery(['eventPage', eventId], () => getEventPage(eventId), { refetchOnWindowFocus: false, });

    useEffect(() => {
        EventPageStore.setFewImagesFromEvent(eventPage?.fewImagesFromTheEvent || []);
        EventPageStore.setImagesCount(eventPage?.imagesCount || 0);
    }, [eventPage])

    useEffect(() => {
        EventPageStore.setEventId(eventId);
    }, [eventId]);

    useEffect(() => {
        return () => {
            EventPageStore.clear();
        }
    }, []);

    useEffect(() => {
        EventPageStore.setIsEventActive(eventPage?.isActive ?? false);
        EventPageStore.setEventName(eventPage?.name ?? '');
    }, [eventPage]);

    if (isLoading) {
        return <CircularProgress />;
    }

    if (error) {
        return <div>משהו השתבש אנא נסה שנית מאוחר יותר...</div>;
    }

    //Extend the eventPage object to include the imagesLimit 

    const event = eventPage as IEventPage & { imagesLimit: number };

    return (
        EventPageStore.isEventActive ? <ActiveEventPage
            joinEventLinkUrl={event.joinEventLink}
            activePracticipantsCount={event.activePracticipantsCount}
            allPracticipantsCount={event.allPracticipantsCount}
            imagesLimit={event.imagesLimit}
            name={event.name} /> :
            <NonActiveEventPage name={event.name} />
    );
});

export default EventPage;
